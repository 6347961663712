import { CheckoutService } from './../feature/command/checkout/checkout/service/checkout.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-confirm-uba-payment',
    templateUrl: './confirm-uba-payment.component.html',
    styleUrls: ['./confirm-uba-payment.component.scss']
})
export class ConfirmUbaPaymentComponent implements OnInit {
    loading = true;
    commandeId;
    success = false;
    errorMessage;
    constructor(private route: ActivatedRoute, private paymentSvc: CheckoutService) { }

    ngOnInit(): void {
        this.route.queryParams
            .subscribe(params => {
                console.log(params);
                const ref = params.Ref;
                console.log(ref);
                this.paymentSvc.confirmUbaPayment(ref).subscribe(v => {
                    this.commandeId = v.idcommande;
                    this.success = true;
                    this.loading = false;
                }, (error => {console.log(error); this.errorMessage = error.error; this.loading = false; }));
            }
            );
    }
}

