import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TestUbaService {

  constructor(private http: HttpClient) { }

  getPaymentInfo() {
    let data = new HttpParams()
      .set('email', 'customer Email')
      .set('firstname', 'customer name')
      .set('lastname', 'customer lastname')
      .set('phone', '09035647')
      .set('merchantID', 'CM0500029')
      .set('uniqueID', '888484482')
      .set('description', 'test pay')
      .set('amount', '200')
      .set('returnUrl', 'test.com')

    return this.http.post(
      'https://cm.instantbillspay.com/api/bill/payment',
      data,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  }
}
