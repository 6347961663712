<div class="page-title-area page-title-bg2"
  style="background-image: url('assets/images/user.png'); background-repeat: no-repeat; background-size: cover;">
  <div class="container">
    <div class="page-title-content">
      <h2>Mes commandes</h2>
      <p>
        <a routerLink="/" class="btn btn-success">Accueil <i class="bx bx-home"></i></a>
      </p>
    </div>
  </div>
</div>
<section class="checkout-area ptb-100">
  <div class="container-xl">
    <div class="user-actions">
      <span>
        <i class='bx bxs-car'></i>
        <a routerLink="automobile" routerLinkActive="now-active">Automobile</a>
      </span> /
      <span>
        <i class='bx bxs-building-house'></i>
        <a routerLink="immobilier" routerLinkActive="now-active">Immobilier</a>
      </span> /
      <span>
        <i class='bx bxs-hotel'></i>
        <a routerLink="hotel" routerLinkActive="now-active">Hotellerie</a>
      </span> /
      <span>
        <i class='bx bxs-spa'></i>
        <a routerLink="tourisme" routerLinkActive="now-active">Tourisme</a>
      </span> /
      <span>
        <i class='bx bxs-book-content'></i>
        <a routerLink="formation" routerLinkActive="now-active">Formation</a>
      </span> /
      <span>
        <i class='bx bxs-palette'></i>
        <a routerLink="couture" routerLinkActive="now-active">Couture</a>
      </span> /
      <span>
        <i class='bx bxs-heart-circle'></i>
        <a routerLink="bien-etre" routerLinkActive="now-active">Bien Etre</a>
      </span> /
      <span>
        <i class='bx bxs-megaphone'></i>
        <a routerLink="evenement" routerLinkActive="now-active">Evenement</a>
      </span> /
      <span>
        <i class='bx bxs-category-alt'></i>
        <a routerLink="ecommerce" routerLinkActive="now-active">E-commerce</a>
      </span> /
      <span>
        <i class='bx bxs-food-menu'></i>
        <a routerLink="traiteur" routerLinkActive="now-active">Restauration</a>
      </span>/
      <span>
        <i class='bx bxs-plane'></i>
        <a routerLink="billetterie" routerLinkActive="now-active">billetterie</a>
      </span>
    </div>
    <router-outlet></router-outlet>
  </div>
</section>
