import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl = environment.BASE_URL;
  private readonly AGZ_USER = 'AGZ_USER';
  private jwtHelperService: JwtHelperService;


  constructor(private http: HttpClient) {
    this.jwtHelperService = new JwtHelperService();
  }

  checkToken(token: any) {
    return this.http.get(this.baseUrl + 'client/confirmuser/' + token);
  }
  login(username: string, password: string) {
    return this.http.post<any>(`${environment.BASE_URL}authenticate`, { username, password }, { observe: 'response' })
      .pipe(map(response => {
        const token = response.body.token;

        const decodedToken = this.jwtHelperService.decodeToken(token);

        const user = new User();
        user.token = token;
        user.username = decodedToken.sub;
        user.role = decodedToken.role[0]?.intitule;
        user.email = decodedToken.email;

        localStorage.setItem(this.AGZ_USER, JSON.stringify(user));
      }));
  }

  currentUserValue(): User {
    return JSON.parse(localStorage.getItem(this.AGZ_USER)) as User;
  }

  getDecodedToken(): any {
    return this.jwtHelperService.decodeToken(this.currentUserValue().token);
  }

  register(user: User): Observable<User> {
    return this.http.post<User>(this.baseUrl + 'client/adduserclient', user);
  }

  updateUser(user: User): Observable<User> {
    return this.http.post<User>(this.baseUrl + 'client/update', user);
  }

  getUser(): Observable<User> {
    return this.http.get<User>(this.baseUrl + 'admin/getuserbyname/' + this.getDecodedToken().sub);
  }

  isAuthenticated(): boolean {
    // console.log("token", this.currentUserValue());
    const user = this.currentUserValue();
    if (user) {
      return this.jwtHelperService.isTokenExpired(user.token);
    }
    return true;
  }

  logout() {
    localStorage.clear();
    // location.href = '/';
  }
}
