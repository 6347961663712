import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit {

  token: any;
  constructor(private activateRoute: ActivatedRoute, private toast: ToastrService, private auth: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.token = this.activateRoute.snapshot.params.token;

    if(this.token){
      this.auth.checkToken(this.token).subscribe(
        data => {
          this.toast.success("Votre compte a été activé avec succès !!!");

          this.router.navigate(['/login']);
        }, (error: HttpErrorResponse) => {
          this.toast.warning("Compte déjà validé ou token invalide !", "Attention");
          this.router.navigate(['/register']);
        }
      )
    }
  }

}
