import { AuthService } from 'src/app/auth/service/auth.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { CategorieEnum } from 'src/app/feature/web/tools/constants/categorie';
import { ServiceEnum } from 'src/app/feature/web/tools/constants/service';
import { Payment } from 'src/app/feature/web/tools/models/order-immo';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  baseUrl = environment.BASE_URL;
  ubaBaseUrl = environment.UBA.baseAPI;

  payWithMomoOrOM(idcmd: number, tel: number): Observable<any> {
    return this.http.post(this.baseUrl + 'client/addpay', {
      commandeid: {
        idcommande: idcmd
      },
      telclient: tel
    }, {responseType: 'text'});
  }

  getAllPayementMode(): Observable<Payment[]> {
    return this.http.get<Payment[]>(this.baseUrl + 'client/listeactifmodepaiement');
  }

  // makePayment(commande): Observable<any> {
  //   return this.http.post(this.baseUrl + 'client​/addpay')
  // }

  payWithUba(firstname: string, lastname: string, email: string, phone: string, commandId: number, amount: number){
    const payload =  {
        email,
        firstname,
        lastname,
        phone,
        uniqueID: '' + commandId,
        amount: '' + amount
    };


    return this.http.post(this.baseUrl + 'uba/init-payment', payload, { responseType: 'text', headers: new HttpHeaders({
        Accept:  'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
      })});
  }

  confirmUbaPayment(paymentRef: string) {
    return this.http.get<any>(`${this.baseUrl}uba/confirmPayment/${paymentRef}`);
  }

}
