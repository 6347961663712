import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TestUbaService } from './service/test-uba.service';

@Component({
  selector: 'app-test-uba',
  templateUrl: './test-uba.component.html',
  styleUrls: ['./test-uba.component.scss']
})
export class TestUbaComponent implements OnInit {

  data = 'Veuillez patienter...';

  constructor(private testUbaService: TestUbaService, private toast: ToastrService) { }

  ngOnInit(): void {
    this.testUbaService.getPaymentInfo().subscribe(
      (res: any) => {
        console.log("The response", res);
        this.data = res;
      }, (error: HttpErrorResponse) => {
        this.data = 'Impossible d\'initier le paiement'
        this.toast.error("Une erreur est survenue !", "Erreur");
      }
    )
  }

}
