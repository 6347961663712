export class User {
    iduser: number = 0;
    username: string;
    password: string;
    nom: string;
    tel: string;
    email: string;
    role: string;
    isnotify: boolean;
    lieu: string;
    datnais: Date;
    requestMessage: string;

    token: string;
}
