<div class="container-fluid  d-flex justify-content-center">
    <div *ngIf="!loading" class=" pt-70 pb-70">
        <div class="card p-0 border-0 m-0">
          <div class="card-body p-0 mb-2">
            <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="success">
                Votre commande {{commandeId}} a été payé avec success veuillez fermer cette fenêtre et rafraichir la page de la commande
            </div>
            <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="!success">
                Une erreur est survenu lors de la validation de votre paiement : <p> <br> {{errorMessage}} </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center pt-70 pb-70" *ngIf="loading">
        <p class="text-center">
          <ngx-loading-spinner mini></ngx-loading-spinner>
        </p>
        <p class="text-center">Nous recuperons le statut de votre paiement veuillez patienter...</p>
      </div>
</div>

