import { RouterModule } from '@angular/router';
import { ClientService } from './services/client.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { No } from '@angular/animations';
import { NgModule } from '@angular/core';
import { NgxTypedJsModule } from 'ngx-typed-js';

import { AppRoutingModule } from './app-routing.module';

//date personnalisée

// import { FormsModule } from '@angular/forms';
// import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BlockUIModule } from 'ng-block-ui';
import { JwtHeaderInterceptor } from './auth/interceptor/jwt.header.interceptor';
import { AuthService } from './auth/service/auth.service';
import { AlertImmoComponent } from './feature/web/modules/immo/tools/components/alert-immo/alert-immo.component';
import { AlertInfoComponent } from './feature/web/tools/components/alert-info/alert-info.component';
import { AgmCoreModule } from '@agm/core';
import { FormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
/*
import {NgxPaginationModule} from 'ngx-pagination';  */

import { NgxWebstorageModule } from 'ngx-webstorage';
import { ConfirmUbaPaymentComponent } from './confirm-uba-payment/confirm-uba-payment.component';
import { NgxLoadersModule } from '@ngx-lite/loaders';
import { SharedModule } from './shared/shared.module';
import { DatePipe } from '@angular/common';


@NgModule({
    declarations: [
        AppComponent,
        ConfirmUbaPaymentComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        NgxLoadersModule,
        NgxTypedJsModule,
        FormsModule,
        SweetAlert2Module.forRoot(),
        NgxDaterangepickerMd.forRoot(),
        HttpClientModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }),
        BlockUIModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyAwqrRNKkXrLws-HGS44GLKIChgXNj2NcY',
            libraries: ['places'],
            apiVersion: 'quarterly'
        }),
        NgxWebstorageModule.forRoot(),
        SharedModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtHeaderInterceptor, multi: true },
        DatePipe,
        AuthService,
    ],
    entryComponents: [
        AlertInfoComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
