import { NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxLoadersModule } from '@ngx-lite/loaders';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
// import { ToastrModule } from 'ngx-toastr';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { AroundPipe } from './pipe/around.pipe';


@NgModule({
  declarations: [AroundPipe],
  imports: [
    CommonModule,
    RouterModule,

    // ToastrModule.forRoot(),
    NgxLoadersModule,
    NgbModule
  ],
    providers: [DatePipe],
    exports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,

        // ToastrModule,
        NgxLoadersModule,
        NgbModule,
        NgSelectModule,
        AroundPipe
    ]
})
export class SharedModule { }
